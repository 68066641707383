<!--
 * @Author: CL
 * @Date: 2021-06-22 09:31:54
 * @LastEditTime: 2021-06-30 12:55:10
 * @Description: 关于我
-->

<template>
  <div class="about-contain">
    <h1>故事与酒</h1>
    <div class="self">您好，我叫曹磊，一个热爱前端技术的菜鸟！</div>
    <div class="self">98年生，感性，积极，向上，喜欢自由，喜欢写作!</div>
    <div class="self">如果你喜欢技术、喜欢历史、喜欢篮球、喜欢大耳朵图图，我相信我们一定能够成为好朋友！</div>
    <div class="self">首先非常感谢您的访问，如果有什么不对或错误的地方也希望您能够指出！</div>
    <div class="self">
      <div>再次感谢您的到来</div>
      <div>如果您是男孩，祝您：莫愁前路无知己，天下谁人不识君</div>
      <div>如果您是女孩，祝您：乘风破浪会有时，直挂云帆济沧海</div>
    </div>

    <div class="wait">我的故事，敬请期待......</div>

    <GoHome />
  </div>
</template>

<script>
import GoHome from '@/components/GoHome';

export default {
  components: {
    GoHome
  }
}
</script>

<style lang="less" scoped>
.about-contain{
  width: 100%;
  height: 100%;
  padding: 30px 200px;
  box-sizing: border-box;
  overflow-y: scroll;
  background-image:linear-gradient(125deg, rgba(55, 60, 63, 0.5), rgba(116, 118, 122, 0.5), rgba(31, 41, 38, 0.5), rgba(255, 255, 255, 0.5));
	/* 让每一种颜色占据400% */
  background-size: 400%;   
  animation: show 1.5s forwards, move 10s infinite;

  .self{
    margin-top: 30px;

    > div{
      margin-top: 20px;
    }
  }

  .wait{
    margin-top: 50px;
    font-size: 18px;
  }
}

@keyframes show{
  0%{
    opacity: 0;
  }

  100%{
    opacity: 1;
  }
}

@keyframes move {
	0% {
    background-position: 0% 50%;
  }
  50% {
	  background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>